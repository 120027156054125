
    import {onMounted, reactive, toRefs, computed, ref ,nextTick } from "vue";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import {baseUrl} from "@/utils/config";
    import apiOrder from "@/request/apis/api_logistics_order";
    import {UserOutlined} from "@ant-design/icons-vue";
    import api_ssy from "@/request/apis/api_ssy";
    interface IState {
        list:any,
        searches:any,
        total:any,
        liveUrl:any;
        visable:any;
        player:any;
        disId:any;
        disVisable:any;
        reason:any;
        unblocking_time:any;
        url:any;
        unlockVisable:boolean
    }

    export default ({
        name: 'ArticleList',
        components: {UserOutlined},
        setup() {
            const state: IState = reactive({
                reason:'',
                url:'',
                unblocking_time:'',
                unlockVisable:false,
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    title:""
                },
                total:0,
                list:[],
                liveUrl:'',
                visable:false,
                player: null,
                disId:'',
                disVisable:false
            })

            const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align:"center"
        },
        {
            title: "标题",
            dataIndex: "title",
            key: "title",
            align:"center"
        },
        {
            title: "描述",
            dataIndex: "description",
            key: "description",
            align:"center"
        },
        {
            title: "直播封面",
            dataIndex: "cover",
            key: "cover",
            align:"center"
        },
        {
            title: "状态",
            dataIndex: "is_online",
            key: "is_online",
            align:"center"
        },
        {
            title: "发布时间",
            dataIndex: "create_time",
            key: "create_time",
            align:"center"
        },
        {
            title: "解封时间",
            dataIndex: "unblocking_time",
            key: "unblocking_time",
            align:"center"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            align:"center"
        },
    ];

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await api_ssy.liveList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.list
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const onDIs = async(val)=>{
                state.reason = ''
                state.unblocking_time = ''
                state.disId = val.id
                state.disVisable = true
            }

            const unlock = async(val)=>{
                state.reason = ''
                state.disId = val.disable.id
                state.unlockVisable = true
            }

            

            const closeLive = async() =>{
                const params = {id:state.disId,reason:state.reason,unblocking_time:state.unblocking_time}
                const res = await api_ssy.liveDisable(params)
                if(res.error_code == 0){
                   getList()
                   message.success(res.msg)
                   state.disVisable = false
                }else{
                    message.error(res.msg)
                }
            }

            const unlockLive = async() =>{
                const params = {id:state.disId,reason:state.reason}
                const res = await api_ssy.liveAble(params)
                if(res.error_code == 0){
                   getList()
                   message.success(res.msg)
                   state.unlockVisable = false
                }else{
                    message.error(res.msg)
                }
            }

            

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const getdetail = async (record) => {
                state.visable = true
                options.src = record.play_uri
            }

            const handleOk = () => {
                options.src = ''
                state.visable = false
            }
            const onReset = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                state.searches.title=""
                getList()
            }
            const options = reactive({
                // width: "800px", //播放器高度
                // height: "450px", //播放器高度
                src: "", //视频源
                type: 'm3u8', //视频类型
            });



            onMounted(() => {
                getList()
                // init()
            })

            return {
                ...toRefs(state),
                getList,
                options,
                handleOk,
                onReset,
                onDIs,
                closeLive,
                onSearch,
                columns,
                getdetail,
                pagination,
                baseUrl,
                unlock,
                unlockLive,
                handleTableChange,

            }
        }
    })
